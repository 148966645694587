<template>
  <div class="x-content">
    <van-image width="100px" height="100px" :src="logo" class="x-logo" />

    <van-form @submit="onSubmit" class="x-form">
      <div class="x-form-item">
        <div class="x-table">身份证号：</div>
        <input
          type="text"
          v-model="fromData.idCard"
          class="x-inpurt"
          minlength="15"
          placeholder="请输入被保险人身份证号"
        />
      </div>

      <div class="x-form-item">
        <div class="x-table">手机号：</div>
        <input
          type="number"
          v-model="fromData.phone"
          class="x-inpurt"
          placeholder="请输入投保人手机号"
        />
      </div>
      <van-button round block native-type="submit" class="x-submit"
        >查询</van-button
      >
    </van-form>
  </div>
</template>
<script>
import logo from "../assets/logo.png";

export default {
  data() {
    return {
      logo: logo,
      fromData: {
        phone: "", //15914153143
        idCard: "" //0GTJ202012111399805818   0GTJ202012141401764579
        // certificateNo
      }
    };
  },
  methods: {
    //提交表单
    onSubmit(e) {
      if (!this.fromData.idCard) {
        this.$Dialog({ message: "身份证号不能为空！" });
        return;
      }
      // if (this.fromData.idCard.length < 22) {
      //   this.$Dialog({ message: "身份证号长度错误！" });
      //   return;
      // }
      this.$api.searchInsuranceInfo(this.fromData).then((res) => {
        console.log("保单查询==>>", res);
        // this.$router.push({
        //   path: "/noData",
        //   query: { policyUrl: res.data.policyUrl || "" },
        // });
        if (res.data.policyUrl) {
          window.location.href = res.data.policyUrl;
        } else {
          this.$router.push({ path: "/noData", query: { policyUrl: "" } });
        }
      });
    }
  },
  mounted() {
    document.title = "保单查询";
  }
};
</script>
<style lang="less" scoped>
input {
  outline-color: invert;
  outline-style: none;
  outline-width: 0px;
  border: none;
  border-style: none;
  text-shadow: none;
  -webkit-appearance: none;
  -webkit-user-select: text;
  outline-color: transparent;
  box-shadow: none;
}

.x-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;
  background-color: white;
  height: 100vh;

  .x-submit {
    height: 44px;
    background: linear-gradient(136deg, #ffb230 0%, #f76b1c 100%);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    margin-top: 30px;
  }
}

.x-form {
  padding: 0px 32px;
  padding-top: 50px;
  box-sizing: border-box;

  .x-form-item {
    margin-bottom: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .x-table {
      display: inline-block;
      width: 90px;
      height: 34px;
      line-height: 34px;
      font-size: 16px;
      text-align: justify;
      font-weight: bold;
      color: #333333;
      vertical-align: middle;
    }
    .x-table:after {
      content: ".";
      width: 100%;
      display: inline-block;
      overflow: hidden;
      height: 0;
    }

    .x-inpurt {
      padding: 8px 12px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #e4e4e4;
      font-size: 14px;
      font-weight: 400;
      vertical-align: middle;
    }

    .x-inpurt:focus {
      border: 1px solid #ec6941;
    }
  }
}
</style>
